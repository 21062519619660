// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToastButton_toastButtons__jTqtv {\n  display: flex;\n  align-items: center; }\n\n.ToastButton_toastButton__3tktB {\n  cursor: pointer;\n  text-transform: capitalize;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 20px;\n  display: flex; }\n  .ToastButton_toastButton__3tktB svg {\n    padding-left: 8px; }\n  .ToastButton_toastButton__3tktB:not(:first-child) {\n    margin-left: 12px; }\n", "",{"version":3,"sources":["webpack://src/components/UI/Toast/ToastButton/ToastButton.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAA;;AAGrB;EACE,eAAe;EACf,0BAA0B;EAC1B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,aAAa,EAAA;EANf;IASI,iBAAiB,EAAA;EATrB;IAaI,iBAAiB,EAAA","sourcesContent":[".toastButtons {\n  display: flex;\n  align-items: center;\n}\n\n.toastButton {\n  cursor: pointer;\n  text-transform: capitalize;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 20px;\n  display: flex;\n\n  svg {\n    padding-left: 8px;\n  }\n\n  &:not(:first-child) {\n    margin-left: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastButtons": "ToastButton_toastButtons__jTqtv",
	"toastButton": "ToastButton_toastButton__3tktB"
};
export default ___CSS_LOADER_EXPORT___;
