// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Stepper_stepper__3QNMb {\n  width: 100%; }\n", "",{"version":3,"sources":["webpack://src/components/UI/Stepper/Stepper.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA","sourcesContent":[".stepper {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepper": "Stepper_stepper__3QNMb"
};
export default ___CSS_LOADER_EXPORT___;
