// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Icon_icon__pAdqh {\n  display: flex;\n  align-content: center; }\n  .Icon_icon__pAdqh svg {\n    margin: 0 10px; }\n", "",{"version":3,"sources":["webpack://src/components/UI/Icon/Icon.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB,EAAA;EAFvB;IAKI,cAAc,EAAA","sourcesContent":[".icon {\n  display: flex;\n  align-content: center;\n\n  svg {\n    margin: 0 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "Icon_icon__pAdqh"
};
export default ___CSS_LOADER_EXPORT___;
