// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Menu_menu__QKnn4 {\n  opacity: 0;\n  max-height: 100%;\n  transition: all 0.3s ease-in-out; }\n  .Menu_menu__QKnn4.Menu_show__hieBO {\n    opacity: 1; }\n", "",{"version":3,"sources":["webpack://src/components/UI/Menu/Menu.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,gCAAgC,EAAA;EAHlC;IAMI,UAAU,EAAA","sourcesContent":[".menu {\n  opacity: 0;\n  max-height: 100%;\n  transition: all 0.3s ease-in-out;\n\n  &.show {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "Menu_menu__QKnn4",
	"show": "Menu_show__hieBO"
};
export default ___CSS_LOADER_EXPORT___;
