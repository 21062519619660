// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_app__2ziFi {\n  display: flex;\n  flex-direction: column; }\n", "",{"version":3,"sources":["webpack://src/App.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB,EAAA","sourcesContent":[".app {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "App_app__2ziFi"
};
export default ___CSS_LOADER_EXPORT___;
