// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectTokenList_selectTokenList__16NG5 {\n  max-height: 100%;\n  overflow-y: auto; }\n", "",{"version":3,"sources":["webpack://src/components/UI/SelectTokenList/SelectTokenList.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB,EAAA","sourcesContent":[".selectTokenList {\n  max-height: 100%;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectTokenList": "SelectTokenList_selectTokenList__16NG5"
};
export default ___CSS_LOADER_EXPORT___;
