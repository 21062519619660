// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CollapseExpand_collapseExpand__3w5Yq {\n  cursor: pointer;\n  transition: 0.3s ease-in-out; }\n  .CollapseExpand_collapseExpand__3w5Yq.CollapseExpand_isCollapsed__3cpzL {\n    transform: scale(1, -1); }\n", "",{"version":3,"sources":["webpack://src/components/UI/CollapseExpand/CollapseExpand.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,4BAA4B,EAAA;EAF9B;IAKI,uBAAuB,EAAA","sourcesContent":[".collapseExpand {\n  cursor: pointer;\n  transition: 0.3s ease-in-out;\n\n  &.isCollapsed {\n    transform: scale(1, -1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapseExpand": "CollapseExpand_collapseExpand__3w5Yq",
	"isCollapsed": "CollapseExpand_isCollapsed__3cpzL"
};
export default ___CSS_LOADER_EXPORT___;
