// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Account_accountMenu__10hoU {\n  padding: 8px;\n  width: 432px; }\n\n.Account_linkButtons__3E4bp > * {\n  margin-right: 5px; }\n", "",{"version":3,"sources":["webpack://src/components/Features/Account/Account.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY,EAAA;;AAGd;EACE,iBAAiB,EAAA","sourcesContent":[".accountMenu {\n  padding: 8px;\n  width: 432px;\n}\n\n.linkButtons > * {\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountMenu": "Account_accountMenu__10hoU",
	"linkButtons": "Account_linkButtons__3E4bp"
};
export default ___CSS_LOADER_EXPORT___;
