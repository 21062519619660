// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CircleLogo_circleLogo__3h2bz {\n  display: flex;\n  margin: auto;\n  height: 100%; }\n  .CircleLogo_circleLogo__3h2bz img {\n    margin: auto; }\n", "",{"version":3,"sources":["webpack://src/components/UI/CircleLogo/CircleLogo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,YAAY,EAAA;EAHd;IAMI,YAAY,EAAA","sourcesContent":[".circleLogo {\n  display: flex;\n  margin: auto;\n  height: 100%;\n\n  img {\n    margin: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circleLogo": "CircleLogo_circleLogo__3h2bz"
};
export default ___CSS_LOADER_EXPORT___;
