// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TransactionSubmittedModalBody_transactionSubmittedModalBody__268XU {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 24px;\n  padding: 0 5px; }\n  .TransactionSubmittedModalBody_transactionSubmittedModalBody__268XU > *:not(:first-child) {\n    margin-top: 15px; }\n  .TransactionSubmittedModalBody_transactionSubmittedModalBody__268XU > *:not(:last-child) {\n    margin-bottom: 15px; }\n", "",{"version":3,"sources":["webpack://src/components/UI/Modal/TransactionSubmittedModal/TransactionSubmittedModalBody/TransactionSubmittedModalBody.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;EACnB,cAAc,EAAA;EALhB;IASM,gBAAgB,EAAA;EATtB;IAaM,mBAAmB,EAAA","sourcesContent":[".transactionSubmittedModalBody {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 24px;\n  padding: 0 5px;\n\n  & > * {\n    &:not(:first-child) {\n      margin-top: 15px;\n    }\n\n    &:not(:last-child) {\n      margin-bottom: 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactionSubmittedModalBody": "TransactionSubmittedModalBody_transactionSubmittedModalBody__268XU"
};
export default ___CSS_LOADER_EXPORT___;
