// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainMenuButton_mainMenuButton__3WcjN {\n  width: 100%;\n  margin: 0;\n  border-width: 0;\n  font-weight: 600;\n  padding: 16px 28px; }\n", "",{"version":3,"sources":["webpack://src/components/UI/MainMenuButton/MainMenuButton.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,SAAS;EACT,eAAe;EACf,gBAAgB;EAChB,kBAAkB,EAAA","sourcesContent":[".mainMenuButton {\n  width: 100%;\n  margin: 0;\n  border-width: 0;\n  font-weight: 600;\n  padding: 16px 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainMenuButton": "MainMenuButton_mainMenuButton__3WcjN"
};
export default ___CSS_LOADER_EXPORT___;
