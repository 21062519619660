// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProgressModalHeader_progressModalHeader__17uvg {\n  display: flex;\n  align-items: center;\n  margin-top: 12px; }\n  .ProgressModalHeader_progressModalHeader__17uvg .ProgressModalHeader_title__16HiU {\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 32px;\n    margin-left: 8px; }\n", "",{"version":3,"sources":["webpack://src/components/UI/Modal/ProgressModal/ProgressModalHeader/ProgressModalHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB,EAAA;EAHlB;IAMI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,gBAAgB,EAAA","sourcesContent":[".progressModalHeader {\n  display: flex;\n  align-items: center;\n  margin-top: 12px;\n\n  .title {\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 32px;\n    margin-left: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressModalHeader": "ProgressModalHeader_progressModalHeader__17uvg",
	"title": "ProgressModalHeader_title__16HiU"
};
export default ___CSS_LOADER_EXPORT___;
