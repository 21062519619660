// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorBlack": "#000",
	"colorWhite": "#fff",
	"colorGainsboro": "#d3dae3",
	"colorGainsboro1": "#d5d9eb",
	"colorSpaceCadet": "#16184e",
	"colorSpaceCadet2": "#23255b",
	"colorOrangeSoda": "#f6643c",
	"colorJasper": "#da4646",
	"colorIndigo": "#292c6c",
	"colorDarkSlateBlue": "#3f4183",
	"colorToolbox": "#717bbc",
	"colorGraniteGray": "#5f666c",
	"colorAuroMetalSaurus": "#667085",
	"colorVeryLightBlue": "#5865f2",
	"colorWhiteOp10": "rgba(255, 255, 255, 0.1)",
	"colorWhiteOp20": "rgba(255, 255, 255, 0.2)",
	"colorLightSteelBlue": "#b3b8db",
	"colorVeryLightAzure": "#69b7f9",
	"colorDodgerBlue": "#1595ff",
	"colorFlame": "#e74f25",
	"colorInternationalOrangeAerospace": "#ff4f0a",
	"colorDarkBlueGray": "#65668c",
	"colorBrilliantAzure": "#4295f7",
	"colorBrightNavyBlue": "#287adb",
	"colorLapizLazuli": "#3467a3"
};
export default ___CSS_LOADER_EXPORT___;
