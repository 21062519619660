// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NetworkSwap_networkSwap__rwBNL {\n  position: absolute;\n  z-index: 1;\n  left: 50%;\n  transform: translate(-50%, calc(-50% - 2px));\n  cursor: pointer;\n  transition: 0.3s ease-in-out; }\n  .NetworkSwap_networkSwap__rwBNL svg {\n    transition: 0.2s ease-in-out;\n    margin: auto;\n    height: 100%;\n    display: flex; }\n  .NetworkSwap_networkSwap__rwBNL.NetworkSwap_flipped__1MZAz svg {\n    transform: scale(1, -1); }\n  .NetworkSwap_networkSwap__rwBNL:hover {\n    filter: brightness(1.4); }\n", "",{"version":3,"sources":["webpack://src/components/UI/NetworkSwap/NetworkSwap.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,4CAA4C;EAC5C,eAAe;EACf,4BAA4B,EAAA;EAN9B;IASI,4BAA4B;IAC5B,YAAY;IACZ,YAAY;IACZ,aAAa,EAAA;EAZjB;IAiBM,uBAAuB,EAAA;EAjB7B;IAsBI,uBAAuB,EAAA","sourcesContent":[".networkSwap {\n  position: absolute;\n  z-index: 1;\n  left: 50%;\n  transform: translate(-50%, calc(-50% - 2px));\n  cursor: pointer;\n  transition: 0.3s ease-in-out;\n\n  svg {\n    transition: 0.2s ease-in-out;\n    margin: auto;\n    height: 100%;\n    display: flex;\n  }\n\n  &.flipped {\n    svg {\n      transform: scale(1, -1);\n    }\n  }\n\n  &:hover {\n    filter: brightness(1.4);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"networkSwap": "NetworkSwap_networkSwap__rwBNL",
	"flipped": "NetworkSwap_flipped__1MZAz"
};
export default ___CSS_LOADER_EXPORT___;
