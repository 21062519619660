// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProgressModalBody_progressModalBody__2pTZ9 {\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n  .ProgressModalBody_progressModalBody__2pTZ9 > * {\n    font-weight: 500;\n    font-size: 12px;\n    line-height: 16px; }\n    .ProgressModalBody_progressModalBody__2pTZ9 > *:not(:first-child) {\n      margin-top: 15px; }\n    .ProgressModalBody_progressModalBody__2pTZ9 > *:not(:last-child) {\n      margin-bottom: 15px; }\n", "",{"version":3,"sources":["webpack://src/components/UI/Modal/ProgressModal/ProgressModalBody/ProgressModalBody.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAA;EAHrB;IAMI,gBAAgB;IAChB,eAAe;IACf,iBAAiB,EAAA;IARrB;MAWM,gBAAgB,EAAA;IAXtB;MAeM,mBAAmB,EAAA","sourcesContent":[".progressModalBody {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  & > * {\n    font-weight: 500;\n    font-size: 12px;\n    line-height: 16px;\n\n    &:not(:first-child) {\n      margin-top: 15px;\n    }\n\n    &:not(:last-child) {\n      margin-bottom: 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressModalBody": "ProgressModalBody_progressModalBody__2pTZ9"
};
export default ___CSS_LOADER_EXPORT___;
